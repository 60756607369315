var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('v-card',{staticClass:"pa-sm-4 wraperx",attrs:{"flat":"","outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1):_c('v-card',{staticClass:"pa-sm-4 wraperx",attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Projects "),_c('v-spacer'),(
          _vm.$_checkPermission(
            this.auth.permissions,
            'Manage Projects',
            'Create'
          )
        )?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Project ")],1):_vm._e()],1),_c('v-card-title',{staticClass:"primary--text"},[_c('v-text-field',{attrs:{"filled":"","dense":"","outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"change":function($event){return _vm.modifyQuery('search', _vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-0 px-0 mt-1",attrs:{"depressed":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" more ")])]}},{key:"item.description",fn:function({ item }){return [_c('v-sheet',{attrs:{"max-width":"300","color":"transparent"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.staff.first_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.staff ? item.staff.first_name + " " + item.staff.last_name : "Not assign")+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }