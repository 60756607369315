<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Projects
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Projects',
              'Create'
            )
          "
        >
          <v-icon left> mdi-plus </v-icon> New Project
        </v-btn>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-text-field
          filled
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @change="modifyQuery('search', search)"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
        <template v-slot:item.description="{ item }">
          <v-sheet max-width="300" color="transparent">
            {{ item.description }}
          </v-sheet>
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  fetchAndStoreProjects,
  syncOfflineProjectsChanges,
} from "@/services/projectsService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    newQuery: {},
    loading: false,
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Code", value: "code" },
      { text: "Coordinator", value: "staff.first_name" },
      // { text: "Description", value: "description" },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
  }),
  async created() {
    if (
      this.$_checkPermission(this.auth.permissions, "Manage Projects", "Read")
    ) {
      this.loading = true;
      await syncOfflineProjectsChanges();
      this.items = await fetchAndStoreProjects();
      const query = this.$route.query;
      this.search = query.search || this.search;
      this.loading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      if (JSON.stringify(this.newQuery) != JSON.stringify(this.$route.query)) {
        this.$router.replace({ path: this.$route.path, query: this.newQuery });
      }
      this.$router.push({
        name: "PageViewProject",
        params: { uuid: item.uuid },
        query: { bc: item.name },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewProject" });
    },
    modifyQuery(queryKey, value) {
      // Create the new query object with updated value
      this.newQuery = { ...this.$route.query, [queryKey]: value || undefined };

      // Check if the query parameters have changed
      // const isSameQuery =
      //   JSON.stringify(newQuery) === JSON.stringify(this.$route.query);

      // If the query has not changed, do nothing
      // if (isSameQuery) {
      //   return;
      // }

      // Otherwise, update the query with replace (avoiding page reload)
      // this.$router.replace({ path: this.$route.path, query: newQuery });
    },
  },
};
</script>